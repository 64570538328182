<template>
  <div>
    <div v-if="label" class="d-flex">
      <p
        v-show="label && $attrs.filled === undefined"
        class="text-subtitle-1 mb-2"
        v-text="label"
      />
      <div v-if="tooltip">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" class="pl-2" small v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </div>
    </div>
    <v-select
      v-bind="$attrs"
      :background-color="backgroundColor"
      class="base-select-field"
      flat
      hide-details="auto"
      :label="$attrs.filled !== undefined ? label : undefined"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: "BaseSelect",

  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },

    label: {
      type: String,
      default: "",
    },

    tooltip: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss">
// Override default text field styles

$input-background: #f8f8f8;
$input-border: #d9d9d9;

.base-select-field {
  &.v-text-field--filled {
    .v-input__slot {
      &::before {
        border: none !important;
      }
    }
  }

  &.v-text-field--outlined {
    border-radius: 0px !important;

    &:not(.error--text) fieldset {
      border-color: $input-border;
    }

    fieldset {
      padding-left: 14px;
    }

    .v-label {
      top: 14px;
    }

    .v-text-field__details {
      padding: 0 18px !important;
    }

    .v-input__slot {
      padding: 0 18px !important;
      min-height: 48px !important;
      background-color: $input-background !important;
    }
  }

  .v-input__append-inner {
    margin-top: 12px;
  }

  .v-input--is-label-active {
    top: 100px !important;
  }
}
</style>
