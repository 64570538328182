import Vue from "vue"
import VueRouter from "vue-router"

// Components
import SSO from "@/views/auth/SSO.vue"
import Dashboard from "@/views/dashboard/Dashboard.vue"

import { getAuthInfo } from "@/utils/helpers"
import NotFound from "@/views/NotFound.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "SSO",
    component: SSO,
    beforeEnter: (to, from, next) => {
      if (getAuthInfo()) {
        next({
          name: "Home",
        })
      }
      next()
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    children: [
      {
        path: "assignments",
        name: "assignments",
        component: () =>
          import("@/views/dashboard/assignments/Assignments.vue"),
        children: [
          {
            path: "home",
            name: "Home",
            component: () =>
              // Used to be ByBatch here but now that screen is removed
              import("@/views/dashboard/assignments/ByAssignment.vue"),
          },
          {
            path: "student",
            name: "Student",
            component: () =>
              import("@/views/dashboard/assignments/ByStudent.vue"),
          },
          {
            path: "assignment",
            name: "Assignment",
            component: () =>
              import("@/views/dashboard/assignments/ByAssignment.vue"),
          },
          {
            path: "view/:assignmentId",
            name: "ViewAssignment",
            component: () =>
              import("@/views/dashboard/assignments/ViewAssignment.vue"),
          },
          {
            path: "batch/:batchId",
            name: "ViewBatch",
            component: () =>
              import("@/views/dashboard/assignments/ViewBatch.vue"),
          },
          {
            path: "student/:studentId",
            name: "ViewStudent",
            component: () =>
              import("@/views/dashboard/assignments/ViewStudent.vue"),
          },
          {
            path: "check",
            name: "StartChecking",
            component: () =>
              import("@/views/dashboard/assignments/ViewSubmission.vue"),
          },
          {
            path: "check/:submissionId",
            name: "ViewSubmission",
            component: () =>
              import("@/views/dashboard/assignments/ViewSubmission.vue"),
          },
        ],
      },
      {
        path: "re-evaluation",
        name: "re-evaluation",
        component: () =>
          import("@/views/dashboard/re_evaluation/ReEvaluation.vue"),
      },
      {
        path: "classes",
        name: "classes",
        component: () => import("@/views/dashboard/classes/Classes.vue"),
      },
      {
        path: "classes/batch/:batchId",
        name: "ViewBatchStudentList",
        component: () => import("@/views/dashboard/classes/ViewBatch.vue"),
        children: [
          {
            path: "student-list",
            name: "StudentList",
            component: () =>
              import("@/views/dashboard/classes/StudentList.vue"),
          },
          {
            path: "upcoming-classes",
            name: "UpcomingClasses",
            component: () =>
              import("@/views/dashboard/classes/UpcomingClasses.vue"),
          },
          {
            path: "previous-classes",
            name: "PreviousClasses",
            component: () =>
              import("@/views/dashboard/classes/PreviousClasses.vue"),
          },
        ],
      },
      {
        path: "classes/batches/:batchId/student/:studentId",
        name: "ViewStudentList",
        component: () => import("@/views/dashboard/classes/ViewStudent.vue"),
      },
      {
        path: "classes/class/:classId",
        name: "ViewLectureStudentList",
        component: () => import("@/views/dashboard/classes/ViewLecture.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!getAuthInfo()) {
        next({
          name: "SSO",
        })
      }
      next()
    },
  },
  // ZOOM OAUTH2 authentication url
  {
    path: "/zoom/auth/",
    name: "zoomAuth",
    component: () => import("@/views/auth/AuthenticateZoom.vue"),
    beforeEnter: (to, from, next) => {
      if (!getAuthInfo()) {
        next({
          name: "classes",
        })
      }
      next()
    },
  },
  { path: "*", component: NotFound },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
