<template>
  <div class="login-container">
    <v-container>
      <v-row justify="center" no-gutters>
        <v-col cols="12" lg="8" xl="6">
          <v-card class="pt-12 mt-4" tile>
            <div class="mb-6">
              <div class="mb-10 d-flex flex-column justify-center align-center">
                <v-img
                  class="mt-6 mb-10"
                  :src="require('@/assets/logos/suraasa-logo.svg')"
                  width="115"
                />
                <p v-if="loading" class="text-h6 mb-1">
                  Authenticating
                </p>
                <v-progress-circular
                  v-if="loading"
                  class="mr-2 mb-6"
                  color="primary"
                  indeterminate
                  :loading="loading"
                  :size="40"
                />
                <template v-if="Boolean(error) && !loading">
                  <p class="display-1 text-center py-4 footer">
                    {{ error }}
                  </p>
                  <v-btn
                    class="px-14"
                    color="primary"
                    depressed
                    height="45px"
                    type="submit"
                    @click="getCode"
                  >
                    <p class="text-body-1">
                      Click here to try again
                    </p>
                  </v-btn>
                </template>
              </div>
              <p class=" text-body-1 text-center py-4 footer">
                For any assistance reach out at care@suraasa.com
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import urls from "@/utils/urls"
import { saveAuthInfo } from "@/utils/helpers"
import { USER_TYPE } from "@/utils/constants"

const SSO_URL = process.env.VUE_APP_SSO_URL

export default {
  name: "SSO",
  data: () => ({
    loading: true,
    error: "",
  }),

  computed: {
    code() {
      return this.$route.query.code
    },
  },

  mounted() {
    if (!this.code) {
      this.getCode()
    } else {
      this.getPlatformToken()
    }
  },

  methods: {
    async getPlatformToken() {
      try {
        this.loading = true
        const res = await this.$http.post(urls.auth.generatePlatformToken, {
          code: this.code,
          platform: USER_TYPE,
        })
        saveAuthInfo(res.data.data)
        this.$router.push(
          this.$route.query.next
            ? this.$route.query.next
            : {
                name: "Home",
              }
        )
      } catch (error) {
        this.error = "Something went wrong!"
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getCode() {
      window.location.href = `${SSO_URL}/?platform=${USER_TYPE}&redirect-url=${window.location.href}`
    },
  },
}
</script>

<style lang="scss">
$input-background: #f8f8f8;
$input-border: #d9d9d9;
$footer-text: #61687e;

.login-container {
  background: #eef2f4 !important;
}
</style>
