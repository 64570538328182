<template>
  <div>
    <p
      v-show="label && $attrs.filled === undefined"
      class="text-subtitle-1 mb-2"
      v-text="label"
    />
    <v-autocomplete
      v-bind="$attrs"
      :background-color="backgroundColor"
      class="base-autocomplete-field"
      flat
      hide-details="auto"
      :label="$attrs.filled !== undefined ? label : undefined"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: "BaseAutocomplete",

  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },

    label: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss">
// Override default text field styles

$input-background: #f8f8f8;
$input-border: #d9d9d9;

.base-autocomplete-field {
  &.v-text-field--filled {
    .v-input__slot {
      &::before {
        border: none !important;
      }
    }
  }

  &.v-text-field--outlined {
    border-radius: 0px !important;

    &:not(.error--text) fieldset {
      border-color: $input-border;
    }

    fieldset {
      padding-left: 14px;
    }

    .v-label {
      top: 14px;
    }

    .v-text-field__details {
      padding: 0 18px !important;
    }

    .v-input__slot {
      padding: 0 18px !important;
      min-height: 48px !important;
      background-color: $input-background !important;
    }
  }

  .v-input__append-inner {
    margin-top: 12px;
  }

  .v-input--is-label-active {
    top: 100px !important;
  }
}
</style>
