export default {
  state: {
    toasts: [],
  },
  getters: {
    toast: state => state.toasts,
  },
  actions: {
    createToast({ commit }, payload) {
      commit("CREATE_TOASTS", payload)
    },
    deleteToast({ commit }, payload) {
      commit("DELETE_TOASTS", payload)
    },
  },
  mutations: {
    CREATE_TOASTS(state, payload) {
      state.toasts.unshift(payload)
    },
    DELETE_TOASTS(state, payload) {
      const item = state.toasts.filter(toast => toast.id !== payload)
      state.toasts = [...item]
    },
  },
}
