<template>
  <div>
    <div v-if="label" class="d-flex">
      <p v-show="label && $attrs.filled === undefined" class="mb-2">
        {{ label }}
      </p>
      <div v-if="tooltip">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" class="pl-2" small v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </div>
    </div>
    <v-combobox
      v-bind="$attrs"
      class="base-autocomplete-field"
      hide-details="auto"
      item-text="name"
      item-value="uuid"
      outlined
      :placeholder="placeholder"
      return-object
      v-on="$listeners"
    >
      <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot v-bind="scope" :name="slot" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: "BaseCombobox",
  props: {
    placeholder: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    tooltip: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss"></style>
