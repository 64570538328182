<template>
  <v-data-table v-bind="$attrs" hide-default-footer v-on="$listeners">
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot v-bind="scope" :name="slot" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "BaseDataTable",
}
</script>

<style lang="scss">
// Override default data table styles
.v-data-table {
  thead {
    background-color: var(--v-secondary-base);
  }

  th {
    border-bottom: none !important;
    color: var(--v-lightCarbon-base) !important;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  tr {
    $row-padding: 30px;
    // $breakpoint-mobile: 600px;
    // @media only screen and (max-width: $breakpoint-mobile) {
    //   $row-padding: 16px;
    // }

    & > td:first-child {
      padding-left: $row-padding !important;
    }

    /* & > td:last-child {
      padding-right: 0 !important;
    } */

    & > th:first-child {
      padding-left: $row-padding !important;
    }

    & > th:last-child {
      padding-right: 0 !important;
    }
  }
}
</style>
