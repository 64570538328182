<template>
  <div>
    <v-avatar v-if="photo && !URLIsBroken" :size="size">
      <img
        alt="avatar"
        :src="photo"
        :style="{ objectFit: 'cover' }"
        @error="URLIsBroken = true"
      />
    </v-avatar>
    <v-avatar v-else-if="name" class="primary lighten-2" :size="size">
      <strong class="initials">
        {{ initials }}
      </strong>
    </v-avatar>
    <v-avatar v-else color="primary lighten-2" :size="size" />
  </div>
</template>

<script>
export default {
  name: "BaseAvatar",

  props: {
    photo: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "40",
    },
  },

  data: () => ({
    URLIsBroken: false,
  }),

  computed: {
    initials() {
      return this.name
        .split(" ")
        .map(n => n[0])
        .join("")
    },
  },
}
</script>

<style scoped lang="scss">
.initials {
  text-transform: uppercase;
  color: white;
}
</style>
