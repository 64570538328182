<template>
  <v-btn class="pl-0 pr-1" color="primary" small text @click="handleClick()">
    <v-icon small>
      mdi-chevron-left
    </v-icon>
    BACK
  </v-btn>
</template>

<script>
export default {
  name: "BackButton",

  props: {
    route: {
      type: Object,
      default: null,
    },
  },

  methods: {
    handleClick() {
      window.history.length > 1
        ? this.route
          ? this.$router.push(this.route)
          : this.$router.go(-1)
        : this.$router.push({ name: "dashboard" })
    },
  },
}
</script>

<style lang="scss" scoped></style>
