// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// kebab-case version of their file name.

import Vue from "vue"
import kebabCase from "lodash/kebabCase"

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  ".",
  // Do not look in subdirectories
  false,
  // Only include "Base" prefixed .vue files
  /Base[\w-]+\.vue$/
)

// For each matching file name...
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName)

  const componentName = fileName
    // Remove the "./_" from the beginning
    .replace(/^\.\//, "")
    // Remove the file extension from the end
    .replace(/\.\w+$/, "")

  // Globally register the component
  Vue.component(
    // Get the kebab-case version of the component name
    kebabCase(componentName),
    componentConfig.default || componentConfig
  )
})
