<template>
  <div>
    <div v-if="label" class="d-flex">
      <p v-show="label" class="text-subtitle-1 mb-2" v-text="label" />
      <div v-if="tooltip">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" class="pl-2" small v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </div>
    </div>
    <v-text-field
      v-bind="$attrs"
      class="base-text-field"
      :class="{ 'mt-9': label === '' }"
      :prepend-inner-icon="searchIcon ? 'mdi-magnify' : ''"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: "BaseTextField",

  props: {
    /** Prepends a magnify icon in the icon-prepend slot of v-text-field */
    searchIcon: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: undefined,
    },

    tooltip: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss">
// Override default text field styles

$input-background: #f8f8f8;
$input-border: #d9d9d9;

.base-text-field {
  // 'solo' text field styles
  &.v-text-field--solo {
    .v-input__slot {
      padding: 12px 30px !important;

      .v-input__prepend-inner {
        margin-right: 10px !important;
      }
    }
  }

  // 'outlined' text field styles
  &.v-text-field--outlined {
    & {
      border-radius: 0px !important;
    }

    &:not(.error--text) fieldset {
      border-color: $input-border;
    }

    fieldset {
      padding-left: 14px;
    }

    .v-label {
      top: 14px !important;
    }

    .v-text-field__details {
      padding: 0 18px !important;
    }

    .v-input__slot {
      padding: 0 18px !important;
      min-height: 48px !important;
      background-color: $input-background;
    }
  }
}
</style>
