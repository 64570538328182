import { BROWSER_STORAGE_KEYS } from "./constants"
import urls from "./urls"

export function getAuthInfo() {
  return JSON.parse(localStorage.getItem(BROWSER_STORAGE_KEYS.auth))
}

export function saveAuthInfo(data) {
  localStorage.setItem(BROWSER_STORAGE_KEYS.auth, JSON.stringify(data))
}

export function deleteAuthInfo() {
  localStorage.removeItem(BROWSER_STORAGE_KEYS.auth)
  localStorage.removeItem(BROWSER_STORAGE_KEYS.zoom)
}

export function getValueOrNull(value) {
  return value ? value : null
}

export function getZoomAuthToken() {
  return JSON.parse(localStorage.getItem(BROWSER_STORAGE_KEYS.zoom))
}

export function setZoomAuthToken(data) {
  localStorage.setItem(BROWSER_STORAGE_KEYS.zoom, JSON.stringify(data))
}

export function verifyZoomToken(http, zoomAuthData) {
  http
    .post(urls.auth.verifyZoomToken, {
      access_token: zoomAuthData.access_token,
    })
    .then(response => {
      if (!response.data.data.verified) {
        refreshZoomToken(http, zoomAuthData)
      }
    })
    .catch(() => {
      refreshZoomToken(http, zoomAuthData)
    })
}

export function refreshZoomToken(http, zoomAuthData) {
  http
    .post(urls.auth.refreshZoomToken, {
      refresh_token: zoomAuthData.refresh_token,
    })
    .then(response => {
      if (response.status / 100 === 2) {
        if (response.data.data) {
          localStorage.removeItem(BROWSER_STORAGE_KEYS.zoom)
          setZoomAuthToken(response.data.data)
        }
      }
    })
    .catch(() => {
      localStorage.removeItem(BROWSER_STORAGE_KEYS.zoom)
      window.reload()
    })
}

export function getSortableFieldsArray(sortBy, sortDesc) {
  return sortBy.map((key, i) => (sortDesc[i] === true ? `-${key}` : key))
}
