<template>
  <v-alert
    border="left"
    class="toast"
    :color="color"
    elevation="10"
    :type="type"
  >
    {{ body }}
  </v-alert>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "Toast",
  props: {
    id: {
      type: Number,
      required: true,
    },
    body: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  mounted: function() {
    setTimeout(() => {
      this.deleteToast(this.id)
    }, 3000)
  },

  methods: { ...mapActions(["deleteToast"]) },
}
</script>

<style lang="scss" scoped>
.alert {
  position: relative;
}
.toast {
  position: relative;
}
</style>
