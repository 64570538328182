import store from "../store"

const CREATE_TOAST_ACTION_NAME = "createToast"

function getUniqueId() {
  return Date.now()
}

export const toast = {
  success: (message, options = {}) =>
    store.dispatch(CREATE_TOAST_ACTION_NAME, {
      id: getUniqueId(),
      color: "green",
      type: "success",
      body: message,
      ...options,
    }),
  error: (message, options = {}) =>
    store.dispatch(CREATE_TOAST_ACTION_NAME, {
      id: getUniqueId(),
      color: "red",
      type: "error",
      body: message,
      ...options,
    }),
  info: (message, options = {}) =>
    store.dispatch(CREATE_TOAST_ACTION_NAME, {
      id: getUniqueId(),
      color: "primary",
      type: "info",
      body: message,
      ...options,
    }),
}
