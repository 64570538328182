import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

const SURAASA_COLORS = {
  primary: "#315EFB", // Suraasa Blue
  carbon: "#343B4C",
  lightCarbon: "#7D7D7D",
  pearlGray: "#C6CAD8",
  aluminium: "#EEF2F4",

  red: "#FF564E",
  green: "#28BC00",
  yellow: "#FFC000",
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: SURAASA_COLORS.primary,
        secondary: SURAASA_COLORS.aluminium,

        aluminium: SURAASA_COLORS.aluminium,
        bg: SURAASA_COLORS.aluminium,

        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        darkBlue: "#343B4C",
        lightCarbon: SURAASA_COLORS.lightCarbon,
        darkCarbon: "#A8A8A8",
        pearlGray: SURAASA_COLORS.pearlGray,
      },
    },
  },
})
