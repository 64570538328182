<template>
  <v-app-bar class="px-lg-16 mb-5 suraasa-app-bar" color="white">
    <div>
      <v-img
        alt="suraasa"
        contain
        :src="require('@/assets/logos/suraasa-logo.svg')"
        width="55"
      />
    </div>

    <v-spacer />

    <div class="suraasa-height-100">
      <v-tabs v-model="tab" color="primary">
        <v-tab active-class="primary--text" :to="{ name: 'Home' }">
          Assignments
        </v-tab>
        <v-tab active-class="primary--text" :to="{ name: 're-evaluation' }">
          Re-evaluation
        </v-tab>
        <v-tab to="/dashboard/classes">
          Classes
        </v-tab>
      </v-tabs>
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div class="d-flex align-center" v-on="on">
          <div>
            <v-badge
              bordered
              bottom
              color="green"
              dot
              offset-x="20"
              offset-y="10"
            >
              <v-avatar class="mx-3" size="32">
                <v-icon x-large>mdi-account-circle</v-icon>
              </v-avatar>
            </v-badge>
          </div>
          <div class="d-flex flex-column profile-name">
            <p class="body-2 lightCarbon--text hidden-sm-and-down">
              {{ fullName }}
            </p>
            <p class="subtitle-2 hidden-sm-and-down">
              Suraasa Professor
            </p>
          </div>
        </div>
      </template>
      <v-list>
        <v-list-item class="hidden-md-and-up">
          <v-list-item-subtitle>
            {{ fullName }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { deleteAuthInfo, getAuthInfo } from "@/utils/helpers"
import { USER_TYPE } from "@/utils/constants"

export default {
  data: () => ({
    tab: null,
    avatarUrlIsBroken: false,
  }),

  computed: {
    fullName() {
      const data = getAuthInfo()
      if (data)
        return [data.user.first_name, data.user.last_name]
          .filter(Boolean)
          .join(" ")
      return ""
    },

    avatarUrl() {
      const data = getAuthInfo()
      if (data) return data.user.photo
      return ""
    },
  },

  methods: {
    home() {
      this.$router.push({ name: "Home" })
    },

    async logout() {
      deleteAuthInfo()
      const url = new URL(`${process.env.VUE_APP_SSO_URL}/logout`)
      url.searchParams.append("origin", window.location.origin)
      url.searchParams.append("platform", USER_TYPE)
      window.location.href = url.href
    },
  },
}
</script>
<style lang="scss">
.profile-name {
  cursor: pointer;
  &:hover {
    color: #315dfb !important;
  }
}

.suraasa-app-bar {
  .v-toolbar__content {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.suraasa-height-100,
.suraasa-height-100 > div,
.suraasa-height-100 > div > div {
  height: 100%;
}
</style>
