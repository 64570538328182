// IMPORTANT: Please add SLASH before and after every URL.

export default {
  auth: {
    login: "/user/auth/login/",
    logout: "/user/auth/logout/",
    generatePlatformToken: "/solis/v1/auth/user-tokens/",
    refreshToken: "/solis/v1/auth/refresh-token/",

    verifyZoomToken: "/video-meetings/zoom/access-token/verify/",
    getZoomAuthenticationUrl: `/video-meetings/zoom/auth/`,
    refreshZoomToken: "/video-meetings/zoom/access-token/refresh/",
    getZoomAccessToken: `/video-meetings/zoom/auth/`,
  },
  assignments: {
    list: "/celestial/v1/assignments/",
    overview: assignmentId =>
      `/celestial/v1/assignments/${assignmentId}/overview/`,

    // UPDATED
    getRubrics: assignmentId => `/assignments/${assignmentId}/list/rubrics/`,

    // UPDATED
    markPlagiarised: reviewId => `assignments/reviews/${reviewId}/`,

    reEvaluation: {
      list: "/assignments/assignment-users/",
    },
  },

  plagiarism: {
    get: submissionId => `/hades/v1/submissions/${submissionId}/`,
    create: submissionId => `/hades/v1/submissions/${submissionId}/plagiarism/`,
  },

  submissions: {
    getPreviousSubmissionsList: (assignmentId, studentId) =>
      `/celestial/v1/assignments/${assignmentId}/users/${studentId}/submissions/`,

    // UPDATED
    getRandomSubmission: `/celestial/v1/assignments/unchecked-submission/`,
    getSubmissionById: submissionId =>
      `/celestial/v1/assignments/submissions/${submissionId}/`,

    createInitialReview: `/v1/assignments/reviews/`,
    // TODO: change this to getProfessorMarkedRubrics
    getMarkedRubrics: reviewId => `/assignments/review-rubrics/${reviewId}/`,
    getIQAMarkedRubrics: reviewId =>
      `/assignments/iqa-review-rubrics/${reviewId}/`,

    // UPDATED
    submitReview: "/assignments/review-rubrics/create/",

    submitReEvaluation: reviewId =>
      `/assignments/review-evaluation/${reviewId}/`,

    // UPDATED
    downloadAttachment: attachmentId =>
      `/assignments/submissions/attachments/${attachmentId}/`,
  },

  batches: {
    // UPDATED
    getBatches: "/batch/",
    // UPDATED
    viewBatch: batchId => `/assignments/batch-overview/${batchId}/`,
    // UPDATED
    viewBatchList: batchId => `/batch/${batchId}/user-assignments/`,
  },

  students: {
    // UPDATED
    getStudents: "/celestial/v1/assignments/users/",
    // UPDATED
    overview: studentId =>
      `/celestial/v1/assignments/assignment-user/${studentId}/overview/`,
    // UPDATED
    listAssignments: userId =>
      `/celestial/v1/assignments/users/${userId}/submissions/`,
    viewstudentList: assignmentId =>
      `/celestial/v1/assignments/${assignmentId}/submissions/`,
  },
  classes: {
    getBatches: "/batch/pr/list/",
    getStudentsList: batchId => `/attendance/pr/batch/${batchId}/users/list/`,
    getClasses: batchId => `/attendance/pr/batch/${batchId}/schedule/list/`,
    getStudentClassList: (batchId, userId) =>
      `/attendance/pr/batch/${batchId}/user/${userId}/list/`,
    getStudentClassOverview: (batchId, userId) =>
      `/attendance/pr/batch/${batchId}/user/${userId}/overview/`,
    // Updated
    getClassStudents: classScheduleId =>
      `attendance/class-schedules/${classScheduleId}/users/`,
    // Updated
    markAttendance: attendanceId => `/attendance/${attendanceId}/`,
    // Updated
    sendInvite: classScheduleId =>
      `/attendance/class-schedules/${classScheduleId}/invites/`,

    /**
     * start_date=YYYY-MM-DD
     * end_date=YYYY-MM-DD
     * batch=batch_uuid
     * course=course_uuid
     * query=batch_name
     */
    // Updated
    getCalendarSchedule: "/attendance/class-schedules/",

    // To create a class (zoom)
    createClass: `/virtual-classrooms/`,
  },
  // UPDATED
  courseBatchChoices: "/batch/course/filter/",
}

// updateStudentAttendanceStatus (PUT Method, Screen #21): /attendance/pr/class/<class_uuid>/user/<user_uuid>/update/
// { status : "absent" | "present" }
