import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { toast } from "./utils/toast"
import vuetify from "./plugins/vuetify"
import axiosInstance from "./utils/axios"

// Globally register all `Base`-prefixed components
import "@/components/_globals"

// Sentry
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import { getAuthInfo } from "@/utils/helpers"

if (!process.env.VUE_APP_SSO_URL) {
  throw new Error("Please add VUE_APP_SSO_URL in your environment variables.")
}

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
      }),
    ],
  })
  Sentry.setUser(getAuthInfo())
}

// Moment
import moment from "moment"

// Vue Froala
// Require Froala Editor js file.
require("froala-editor/js/froala_editor.pkgd.min.js")
require("froala-editor/js/plugins.pkgd.min.js")

// Require Froala Editor css files.
require("froala-editor/css/froala_editor.pkgd.min.css")
require("froala-editor/css/froala_style.min.css")

// Import and use Vue Froala lib.
import VueFroala from "vue-froala-wysiwyg"

Vue.use(VueFroala)

Vue.config.productionTip = false

Vue.prototype.$toast = toast
Vue.prototype.$http = axiosInstance
Vue.prototype.moment = moment

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app")
