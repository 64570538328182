export const USER_TYPE = "Professor"

export const BROWSER_STORAGE_KEYS = {
  auth: "suraasaAuth",
  tempDeviceId: "tempDeviceId",
  zoom: "zoomAuth",
}

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_ITEMS_PER_PAGE = [10, 30, 50, 100]

export const REVIEW_STATUS = {
  UNDER_REVIEW: "under_review",
  NOT_REVIEWED: "not_reviewed",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  PLAGIARISED: "plagiarised",
}

export const EVALUATION_STATUS_FOR_ASSIGNMENT_USERS = {
  UNDER_EVALUATION: 0,
  CHANGES_REQUESTED: 1,
  CHALLENGED: 2,
  COMPLETED: 3,
  SKIPPED: 4,
}

export const EVALUATION_STATUS = {
  UNDER_EVALUATION: 0,
  CHANGES_REQUESTED: 1,
  CHALLENGED: 2,
  ACCEPTED: 3,
  REVIEW_CHANGED: 4,
}

export const PLAGIARISM_TYPE = {
  PLAGIARISED: 0,
  WRONGLY_DETECTED: 1,
  NOT_PLAGIARISED: 2,
}

export const PLAGIARISM_OPTIONS = [
  {
    title: "Mark this as not plagiarised",
    value: PLAGIARISM_TYPE.PLAGIARISED,
  },
  {
    title: "This was wrongly detected as plagiarised",
    value: PLAGIARISM_TYPE.WRONGLY_DETECTED,
  },
  {
    title: "This is a common definition",
    value: PLAGIARISM_TYPE.NOT_PLAGIARISED,
  },
]
