<template>
  <div>
    <Navbar />
    <v-container class="container-width" fluid>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/dashboard/Navbar.vue"
import { mapGetters, mapActions } from "vuex"

export default {
  components: { Navbar },
  computed: {
    ...mapGetters(["courseChoices"]),
  },
  mounted: function() {
    this.$nextTick(function() {
      if (this.courseChoices.length < 1) {
        return this.getCourseBatchChoices()
      }
    })
  },

  methods: {
    ...mapActions(["getCourseBatchChoices"]),
  },
}
</script>

<style lang="scss" scoped>
.container-width {
  max-width: 1920px;
  width: 95%;
  padding: 0;
}
</style>
